var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"launch-container"},[_c('div',{staticClass:"form-overflow"},[_c('div',{staticClass:"launch-title"},[_vm._v("发起任务")]),_c('div',{staticClass:"common-grey-line-1px"}),_c('el-form',{ref:"launchTaskForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.launchTaskForm,"rules":_vm.launchTaskRules,"label-width":"140px"}},[_c('div',{staticClass:"taskForm-LeftRight"},[_c('el-form-item',{attrs:{"label":"类型名称：","prop":"workOrderId"}},[_c('el-select',{staticClass:"common-screen-input_100",attrs:{"disabled":_vm.id ? true : false,"placeholder":"请选择类型","filterable":""},on:{"change":function (val) {
                _vm.changeTypeFun(val);
              }},model:{value:(_vm.launchTaskForm.workOrderId),callback:function ($$v) {_vm.$set(_vm.launchTaskForm, "workOrderId", $$v)},expression:"launchTaskForm.workOrderId"}},_vm._l((_vm.taskTypeSelect),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.workTypeName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"任务工单名称：","prop":"taskName"}},[_c('el-input',{staticClass:"common-screen-input_100",attrs:{"placeholder":"任务工单名称"},model:{value:(_vm.launchTaskForm.taskName),callback:function ($$v) {_vm.$set(_vm.launchTaskForm, "taskName", $$v)},expression:"launchTaskForm.taskName"}})],1),_c('el-form-item',{attrs:{"label":"优先级："}},[_c('el-select',{staticClass:"common-screen-input_100",attrs:{"placeholder":"请选择优先级","filterable":""},model:{value:(_vm.launchTaskForm.priority),callback:function ($$v) {_vm.$set(_vm.launchTaskForm, "priority", $$v)},expression:"launchTaskForm.priority"}},[_c('el-option',{attrs:{"label":"低","value":1}}),_c('el-option',{attrs:{"label":"中","value":2}}),_c('el-option',{attrs:{"label":"高","value":3}}),_c('el-option',{attrs:{"label":"紧急","value":4}})],1)],1),_c('el-form-item',{attrs:{"label":"计划开始时间：","prop":"planStartTime"}},[_c('el-date-picker',{staticClass:"common-screen-input_100",attrs:{"picker-options":_vm.startPickerOptions,"value-format":"yyyy-MM-dd HH:mm:ss","type":"datetime","placeholder":"选择日期时间"},on:{"change":function (val) {
                _vm.dateTime(val, 'start');
              }},model:{value:(_vm.launchTaskForm.planStartTime),callback:function ($$v) {_vm.$set(_vm.launchTaskForm, "planStartTime", $$v)},expression:"launchTaskForm.planStartTime"}})],1),_c('el-form-item',{attrs:{"label":"计划完成时间：","prop":"planEndTime"}},[_c('el-date-picker',{staticClass:"common-screen-input_100",attrs:{"picker-options":_vm.endPickerOptions,"value-format":"yyyy-MM-dd HH:mm:ss","type":"datetime","placeholder":"选择日期时间"},on:{"change":function (val) {
                _vm.dateTime(val, 'end');
              }},model:{value:(_vm.launchTaskForm.planEndTime),callback:function ($$v) {_vm.$set(_vm.launchTaskForm, "planEndTime", $$v)},expression:"launchTaskForm.planEndTime"}})],1)],1),_c('div',{staticClass:"taskForm-LeftRight"},[_c('el-form-item',{attrs:{"label":"描述"}},[_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"请输入内容","maxlength":"500","show-word-limit":""},model:{value:(_vm.launchTaskForm.remark),callback:function ($$v) {_vm.$set(_vm.launchTaskForm, "remark", $$v)},expression:"launchTaskForm.remark"}})],1),_c('el-form-item',{attrs:{"label":"附件："}},[_c('div',{staticClass:"common-uploadBnList-container"},[_c('div',{staticClass:"fileBtn-container"},[_c('div',{staticClass:"fileBtn"},[_vm._v(" 上传附件 "),_c('input',{staticClass:"file-input",attrs:{"type":"file"},on:{"input":function (e) {
                      _vm.uploadFile(e);
                    }}})]),_vm._v(" 上传文件及图片，大小不超过50MB ")]),_c('br'),_c('div',{staticClass:"fileList-container"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"fileList-item"},[_c('div',{staticClass:"item-name"},[_c('common-picture',{attrs:{"fileUrl":item.filedUrl,"fileType":item.filedType,"fileName":item.filedName}})],1),_c('div',{staticClass:"deleteBtn",on:{"click":function () {
                      _vm.deleteFlie(index);
                    }}},[_vm._v(" 删除 ")])])}),0)])])],1)]),_c('div',{staticClass:"workOrder-title"},[_vm._v("工单流程")]),(!_vm.launchTaskForm.workOrderId)?_c('div',{staticStyle:{"margin":"16px 0 0 32px","color":"#696969"}},[_vm._v(" 请选择工单类型模板 ")]):_c('div',{staticClass:"workOrder-process-container"},[_c('el-timeline',[_c('el-timeline-item',[_c('template',{slot:"dot"},[_c('div',{staticClass:"timelineItem-dot"},[_vm._v("1")])]),_c('div',{staticClass:"process-title"},[_vm._v("创建工单")])],2),_vm._l((_vm.launchTaskForm.flowList),function(item,index){return _c('el-timeline-item',{key:index},[_c('template',{slot:"dot"},[_c('div',{staticClass:"timelineItem-dot"},[_vm._v(_vm._s(index + 2))])]),_c('div',[_c('div',{staticClass:"process-input-container"},[_c('el-input',{style:(item.status == 0 ? '' : 'background: #efefef'),attrs:{"disabled":item.status == 0 ? false : true,"placeholder":"任务名称"},model:{value:(item.taskFlowName),callback:function ($$v) {_vm.$set(item, "taskFlowName", $$v)},expression:"item.taskFlowName"}},[_c('template',{slot:"prepend"},[_vm._v("任务名称")])],2)],1),_c('div',{staticClass:"process-textarea-container"},[_c('div',{staticClass:"textarea-title"},[_vm._v("任务描述")]),_c('el-input',{attrs:{"disabled":item.status == 0 ? false : true,"type":"textarea","rows":6,"placeholder":"任务描述","maxlength":"500","show-word-limit":""},model:{value:(item.taskFlowDescribe),callback:function ($$v) {_vm.$set(item, "taskFlowDescribe", $$v)},expression:"item.taskFlowDescribe"}})],1),_c('div',{staticClass:"common-uploadBnList-container"},[(item.status == 0)?_c('div',{staticClass:"fileBtn-container"},[_c('div',{staticClass:"fileBtn"},[_vm._v(" 上传附件 "),_c('input',{staticClass:"file-input",attrs:{"type":"file"},on:{"input":function (e) {
                        _vm.uploadFileItem(e, index);
                      }}})]),_vm._v(" 上传文件及图片，大小不超过50MB ")]):_vm._e(),_c('br'),_c('div',{staticClass:"fileList-container"},_vm._l((item.fileList),function(itemTwo,indexTwo){return _c('div',{key:indexTwo,staticClass:"fileList-item"},[_c('div',{staticClass:"item-name"},[_c('common-picture',{attrs:{"fileUrl":itemTwo.filedUrl,"fileType":itemTwo.filedType,"fileName":itemTwo.filedName}})],1),(item.status == 0)?_c('div',{staticClass:"deleteBtn",on:{"click":function () {
                        _vm.deleteFlieItem(index, indexTwo);
                      }}},[_vm._v(" 删除 ")]):_vm._e()])}),0)]),_c('div',{staticClass:"common-multiple-container"},[_c('div',{staticClass:"label"},[_vm._v("处理人")]),_c('div',{staticClass:"content"},_vm._l((item.noticeList),function(itemTwo,indexTwo){return _c('div',{key:indexTwo,staticClass:"multiple-item"},[_vm._v(" "+_vm._s(itemTwo.name)+" ")])}),0),(item.status == 0 && !item.dealId)?_c('div',{staticClass:"multiple-btn",on:{"click":function () {
                    _vm.personShow(item.dealRole, index);
                  }}},[_vm._v(" 选择人员 ")]):_c('div',{staticClass:"multiple-btn",staticStyle:{"color":"#ccc"}},[_vm._v(" 选择人员 ")])]),_c('br')])],2)}),_c('el-timeline-item',[_c('template',{slot:"dot"},[_c('div',{staticClass:"timelineItem-dot"},[_vm._v(" "+_vm._s(_vm.launchTaskForm.flowList.length + 1)+" ")])]),_c('div',{staticClass:"common-multiple-container"},[_c('div',{staticClass:"label"},[_vm._v("抄送人")]),_c('div',{staticClass:"content"},_vm._l((_vm.launchTaskForm.sendList),function(item,index){return _c('div',{key:index,staticClass:"multiple-item"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),(!_vm.launchTaskForm.sendId)?_c('div',{staticClass:"multiple-btn",on:{"click":function () {
                  _vm.personShow(_vm.launchTaskForm.sendRole, 'sendRole');
                }}},[_vm._v(" 选择人员 ")]):_c('div',{staticClass:"multiple-btn",staticStyle:{"color":"#ccc"}},[_vm._v("选择人员")])])],2),_c('el-timeline-item',[_c('template',{slot:"dot"},[_c('div',{staticClass:"timelineItem-dot"},[_vm._v(" "+_vm._s(_vm.launchTaskForm.flowList.length + 2)+" ")])]),_c('div',{staticClass:"common-multiple-container"},[_c('div',{staticClass:"label"},[_vm._v("负责人")]),_c('div',{staticClass:"content"},_vm._l((_vm.launchTaskForm.chargeList),function(item,index){return _c('div',{key:index,staticClass:"multiple-item"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),(!_vm.launchTaskForm.chargeId)?_c('div',{staticClass:"multiple-btn",on:{"click":function () {
                  _vm.personShow(_vm.launchTaskForm.chargeRole, 'chargeRole');
                }}},[_vm._v(" 选择人员 ")]):_c('div',{staticClass:"multiple-btn",staticStyle:{"color":"#ccc"}},[_vm._v("选择人员")])])],2),_c('el-timeline-item',[_c('template',{slot:"dot"},[_c('div',{staticClass:"timelineItem-dot"},[_vm._v(" "+_vm._s(_vm.launchTaskForm.flowList.length + 3)+" ")])]),_c('div',{staticClass:"process-title"},[_vm._v("关闭工单")])],2)],2)],1)],1),_c('div',{staticClass:"common-grey-line"}),_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"btn-content"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {_vm.$router.go(-1)}}},[_vm._v("返回")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {_vm.launchTask('launchTaskForm')}}},[_vm._v(_vm._s(!_vm.$route.query.id ? "立即指派" : "保存"))])],1)]),_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"选择人员","append-to-body":true,"visible":_vm.personVisible,"width":"630px","before-close":function () {
        _vm.personVisible = false;
      },"close-on-click-modal":false},on:{"update:visible":function($event){_vm.personVisible=$event}}},[_c('div',{staticClass:"common-chooseItem-container"},_vm._l((_vm.checkedList),function(item,index){return _c('div',{key:index,staticClass:"common-choose-item"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"el-icon-circle-close",on:{"click":function () {
              _vm.deletePerson(item);
            }}})])}),0),_c('br'),_c('br'),_c('div',{staticClass:"common-person-container"},[_c('div',{staticClass:"person-title"},[_c('div',{staticClass:"left-text"},[_vm._v("组织成员")]),_c('div',{staticClass:"right-seach"},[_c('el-input',{staticClass:"seach-input",attrs:{"placeholder":"请输入内容","prefix-icon":"el-icon-search"},on:{"change":function (val) { return _vm.seachFun(val); }},model:{value:(_vm.seachInfo),callback:function ($$v) {_vm.seachInfo=$$v},expression:"seachInfo"}})],1)]),_c('div',{staticClass:"person-content"},[_c('div',{staticClass:"content-left"},[_c('el-tree',{attrs:{"data":_vm.roleData,"props":_vm.defaultProps},on:{"node-click":function (val) {
                _vm.departTreeFun(val);
              }}})],1),_c('div',{staticClass:"content-right"},_vm._l((_vm.personData),function(item,index){return _c('div',{key:index},[_c('el-checkbox',{on:{"change":function (val) {
                  _vm.changePerson(val, item);
                }},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v(_vm._s(item.nickName))])],1)}),0)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {_vm.personVisible = false}}},[_vm._v("取 消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {_vm.noticeFun()}}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }